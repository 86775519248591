
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance,
} from "vue";
import { ContentLoader } from 'vue-content-loader';
import arraySort from "array-sort";
import { string } from "yup/lib/locale";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
  class?: string;
}

export default defineComponent({
  name: "kt-datatable",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    tableData: { type: Array, required: true },
    classTable: {type: String, required: false },
    asyncPaginate: {type: Boolean, default: false },
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    description: { type: String, default: "Nenhum dado foi encontrado " },
    emptyTableText: { type: String, default: "Nenhum resultado encontrado..." },
    loading: { type: Boolean, default: false },
    backgroundHeader: { type: Boolean, default: true },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    select: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
    ruleSelect: {
      type: Function,
      default: false,
	  },
    itemsSelect: {
      type: Array,
	  },
  },
  components: { ContentLoader },
  setup(props, { emit }) {
    const currentSort = ref("");
    const emptyArray: any[] = [];
    const selected = ref(emptyArray);
    const selectAll = ref(false);
    const order = ref(props.order);
    const label = ref(props.sortLabel);
    const pagination = ref<IPagination>({
      page: 1,
      total: props.total,
      rowsPerPage: props.rowsPerPage,
    });
    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    watch(() => props.select, () => {
      selected.value = [];
    });

    watch(() => props.tableData, () => {
      selectAll.value = false;
      selected.value = [];
      pagination.value.total = props.total ? props.total : props.tableData.length;
    });

    watch(props, () => {
      if ("onCurrentChange" in vnodeProps) {
        currentSort.value = label.value + order.value;
      } else {
        pagination.value.total = props.tableData.length;
      }
    });

    watch(selected, () => {
      if(selected.value.length > 0){
        emit("itemSelected", true);
      } else {
        emit("itemSelected", false);
      }

      if(props.select){
        emit("updateItensSelecteds", selected.value);
      }
    });

    function controlaSelect(){
      if(props.ruleSelect){
        if(selected.value.length == props.tableData.filter((item) => props.ruleSelect(item)).length) {
          selectAll.value = true;
          return
        };
      }

      if(selected.value.length == props.tableData.length) {
        selectAll.value = true;
        return
      };

      selectAll.value = false;
    };

    function controlaSelectAll(){
      if(selectAll.value){
        if(props.ruleSelect){
          selected.value = props.tableData.filter((item, index) => {return (props.asyncPaginate || (index + 1 <= pagination.value.page * pagination.value.rowsPerPage && index + 1 > (pagination.value.page - 1) * pagination.value.rowsPerPage))}).filter((item) => props.ruleSelect(item));
          return
        }

        selected.value = props.tableData.filter((item, index) => {return (props.asyncPaginate || (index + 1 <= pagination.value.page * pagination.value.rowsPerPage && index + 1 > (pagination.value.page - 1) * pagination.value.rowsPerPage))});
        return
      };

      selected.value = [];
    };

    onMounted(() => {
      currentSort.value = label.value + order.value;
      pagination.value.total = props.total ? props.total : props.tableData.length;
      pagination.value.rowsPerPage = props.rowsPerPage;
    });

    const getItems = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        selected.value = [];
        return props.tableData;
      } else {
        const clone = JSON.parse(JSON.stringify(props.tableData));
        const startFrom =
          pagination.value.page * pagination.value.rowsPerPage -
          pagination.value.rowsPerPage;
        return clone.splice(startFrom, pagination.value.rowsPerPage);
      }
    });

    const currentPageChange = (val) => {
      if(selectAll.value){
        selectAll.value = false;
        selected.value = [];
      }

      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        emit("current-change", val);
        pagination.value.page = val;
      }
    };

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }

      if ("onSort" in vnodeProps) {
        if (order.value === "asc") {
          order.value = "desc";
          emit("sort", { columnName: columnName, order: "desc" });
        } else {
          order.value = "asc";
          emit("sort", { columnName: columnName, order: "asc" });
        }
      } else {
        if (order.value === "asc") {
          order.value = "desc";
          arraySort(props.tableData, columnName, { reverse: false });
        } else {
          order.value = "asc";
          arraySort(props.tableData, columnName, { reverse: true });
        }
      }
      currentSort.value = columnName + order.value;
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.value.rowsPerPage = parseInt(event.target.value);
      }
    };

    return {
      pagination,
      currentPageChange,
      getItems,
      sort,
      currentSort,
      setItemsPerPage,
      selected,
      controlaSelectAll,
      controlaSelect,
      selectAll,
    };
  },
});
